body {
    overflow-x: hidden;
}

// Setting menu 
.order_history_container,
.order_detail_container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.order_history_setting_menu {
    width: 270px;
    background-color: #fff7f4;
    padding: 40px 0 50px 0;
    border-right: 1px solid #e0e0e0;

    .order_history_setting_full_name {
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 5px;
        margin-right: 10px;
    }

    .order_history_setting_menu_section p {
        color: #323842;
        font-size: 14px;
    }

    .order_history_setting_menu_section {
        margin-bottom: 30px;
        margin-left: 45px;
    }

    .order_history_setting_menu_items {
        .order_history_setting_menu_item {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.3s;
            padding: 10px;

            &:hover {
                background-color: #f8dcce;
            }

            .order_history_setting_menu_item_name {
                font-size: 14px;
                white-space: nowrap;
            }

            .order_history_setting_menu_icon {
                margin-left: 35px;
            }

            .icon-edit-profile {
                color: #7d6f68;
                margin-right: 10px;
                font-size: 17px;
            }

            .icon-order-history {
                color: #7d6f68;
                margin-right: 13px;
                font-size: 18px;
            }

            .icon-notification {
                color: #7d6f68;
                margin-right: 14px;
                font-size: 19px;
            }
        }

        .order_history_setting_menu_item.order-history-item {
            background-color: #f8dcce;
            font-weight: bold;

            .setting_menu_icon {
                color: black;
            }

            .setting_menu_item_name {
                color: black;
            }
        }
    }
}

// End setting menu 

.order_detail_wrapper {
    width: calc(100% - 253px);
    padding: 80px 13% 80px 13%;

    .order_detail_line1 {
        margin: 0 0 30px 0;
    }

    .order_detail_line2 {
        margin: 30px 0 30px 0;
    }

    .order_detail_line3 {
        margin: 30px 0 10px 0;
    }

    .order_detail_border {
        border: 1px solid #e0e0e0;
        // border-radius: 5px;
        padding: 30px 40px 40px 40px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }

    .order_detail_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h4 {
            margin-bottom: 0;
        }

        .order_detail_number {
            font-size: 20px;
            font-weight: bold;
        }

        .order_detail_status {
            font-size: 15px;
            border: 1px solid #1c1c1c;
            border-radius: 30px;
            padding: 3px 30px 5px 30px;
            color: #1c1c1c;
        }
    }

    .order_detail_product {
        display: flex;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 15px 20px 14px 20px;
        margin-bottom: 20px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);



        .order_detail_product_image {
            width: 130px;
            height: 130px;
            border-radius: 8px;
            margin-right: 20px;
        }

        .order_detail_product_info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .order_detail_product_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .order_detail_product_name {
                font-size: 17px;
                font-weight: bold;
            }

            .order_detail_product_links {
                margin-top: -10px;

                a {
                    color: #1c1c1c;
                    text-decoration: none;
                    cursor: pointer;
                    font-size: 14px;
                }

                a:hover {
                    text-decoration: underline;
                    color: #797979;
                }
            }

            .order_detail_product_description,
            .order_detail_product_id,
            .order_detail_product_size {
                font-size: 14px;
                margin-bottom: 5px;
            }

            .order_detail_product_price {
                font-size: 17px;
                margin-bottom: 5px;
                text-align: end;
                font-weight: bold;
            }
        }
    }

    .order_detail_customer_info {
        margin-top: 20px;

        .order_detail_customer_item {
            display: flex;
            align-items: center;
            font-size: 15px;
            margin-bottom: 10px;

            .fa-user {
                margin-right: 18px;
                font-size: 16px;
            }

            .fa-phone {
                margin-right: 17px;
                font-size: 16px;
            }

            .fa-map-marker-alt {
                margin-right: 20px;
                font-size: 17px;
            }

            .fa-credit-card {
                margin-right: 15px;
                font-size: 16px;
            }

            .fa-sticky-note {
                margin-right: 18px;
                font-size: 17px;
            }
        }
    }

    .order_detail_footer {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .order_detail_date {
            font-size: 18px;
            font-weight: bold;
        }

        .order_detail_total_price {
            font-size: 18px;
            font-weight: bold;

            .order_detail_discount {
                margin-right: 20px;
                color: green;
            }
        }
    }

    .order_detail_actions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .order_detail_contact_us,
        .order_detail_continue_shopping {
            background-color: #1c1c1c;
            color: white;
            padding: 8px 20px;
            border: none;
            border-radius: 30px;
            cursor: pointer;
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48%;

            i {
                margin-right: 10px;
                color: white;
                font-size: 15px;
            }

            &:hover {
                background-color: #797979;
                transform: translateY(-5px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }
        }
    }
}


.OrderDetail :where(.css-dev-only-do-not-override-1pg9a38).ant-image .ant-image-img {
    width: 130px;
    height: 130px;
    border-radius: 8px;
    margin-right: 20px;
}

.OrderDetail .ant-image-mask{
    width: 130px;
    height: 130px;
    border-radius: 8px;
    margin-right: 20px;
}