//modify sidebar
.ss_manage_warranty_all_container {
    display: flex;
    width: 100%;
}

.ss_manage_warranty_content {
    flex-grow: 1;
    padding: 15px 6% 6% 8%;
}

//modify sidebar