/* Footer */
.footer {
    background-color: #fff7f4;
    padding: 90px 0 30px 0;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #e0e0e0;
}

.footer ul {
    list-style: none;
    padding: 0;
}

.footer ul li {
    margin-bottom: 15px;
}

.footer ul li a {
    color: #7d6f68;
    text-decoration: none;
}

.footer ul li a:hover {
    color: #1c1c1c;
}

.footer hr {
    border: 0;
    border-top: 1px solid #7d6f68;
    margin: 30px 0 30px 0;
}

.footer p {
    margin: 0;
    font-size: 14px;
    color: #7d6f68;
}

.footer p a {
    color: #1c1c1c;
    text-decoration: none;
}

.footer p a:hover {
    text-decoration: underline;
    color: #797979;
    cursor: pointer;
}

.footer_content li {
    font-size: 14px;
    margin-top: -4px;
}

.footer_content .fa-phone-volume,
.footer_content .fa-envelope {
    font-size: 15px;
    margin-right: 10px;
    color: #1c1c1c;
}

.footer_content a {
    cursor: pointer;
    color: #7d6f68;
    text-decoration: none;
}

.footer ul li a {
    color: #7d6f68;
    text-decoration: none;
    display: inline-block;
    transition: transform 0.3s ease;
}

.footer ul li a:hover {
    color: #1c1c1c;
    transform: translateY(-2px);
}

.copyright a {
    font-size: 14px;
}

.footer_tos_link {
    font-size: 16px;
    text-decoration: none;
    color: #7d6f68;
}

.footer_tos_link:hover {
    color: #1c1c1c;
}

.copyright .tos_col {
    text-align: end;
    padding-right: 20px;
    font-size: 16px;
}

.tos_col .footer_tos_link {
    color: #7d6f68;
    text-decoration: none;
}

.tos_col .footer_tos_link:hover {
    color: #1c1c1c;
}

/* Modal CSS */
body.modal-open {
    overflow: hidden;
}

.modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}

.modal-content {
    border-radius: 0;
    background-color: #fefefe;
    margin: 50px auto;
    padding: 20px 30px 70px 30px;
    border: 1px solid #888;
    width: 80%;
    max-width: 900px;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

.close {
    color: #aaa;
    float: right;
    font-size: 30px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.tos_title,
.tos_introduce {
    text-align: center;
}

.tos_title {
    font-weight: bold;
}

.icon_gg {
    font-size: 17px;
    color: white;
}



/* Social media icon */
.social_media_icon .fb,
.social_media_icon .ins,
.social_media_icon .tik,
.social_media_icon .gg {
    font-size: 19px;
    margin-top: 12px;
    border: 1px solid #d4d4d4;
    padding: 14px 16px 15px 16px;
    border-radius: 50%;
    color: #1c1c1c;
    transition: all 0.3s ease;
}

.social_media_icon .fb {
    padding: 16px 19px 15px 19px;
    font-size: 17px;
}

.social_media_icon .gg {
    padding: 15px 16px 14px 16px;
}

.social_media_icon i:hover {
    color: white;
    background-color: #f4b798;
    border: 1px solid #f4b798;
    transform: translateY(-2px);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

/* Footer columns */
.footer_first_column img {
    width: 140px;
}

.footer_first_column h6 {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: bold;
    margin: 30px 0 20px 0;
}

.footer_last_column h6 {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 8px 0;
}

.footer_last_column .footer_last_column_title_2 {
    margin-top: -3px;
    margin-bottom: 13px;
}

.footer_2_column h6,
.footer_3_column h6,
.footer_4_column h6 {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 20px 0;
}

.footer_last_column .footer_vnpay {
    width: 40px;
    margin-right: 12px;
    margin-top: -16px;
}

.footer_last_column .footer_bank {
    width: 120px;
    margin-top: -11px;
    margin-right: 10px;
}

.footer_last_column .footer_cash {
    width: 40px;
    margin-top: -11px;
}

.end_footer {
    display: flex;
    white-space: nowrap;
}