.deliverystaff_sidebar {
    width: 250px;
    transition: width 0.3s ease;
    background-color: #fafafb;
    border-right: 1px solid #e0e0e0;
    min-height: 130%;    
    display: flex;
    flex-direction: column;
    z-index: 10;

    &.collapsed {
        width: 60px;

        .toggle-button {
            margin: 20px;
        }

        .deliverystaff_sidebar_icons {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .deliverystaff_sidebar_menu_icons {
            list-style: none;
            padding: 0;
            margin: 0;

            .deliverystaff_sidebar_menu_item {
                padding: 10px;
                text-align: center;
                cursor: pointer;
                position: relative;

                i {
                    font-size: 16px;
                }

                &:hover,
                &.selected {
                    background-color: #e0e0e0;
                }

                &::after {
                    content: attr(data-tooltip);
                    position: absolute;
                    left: calc(100% + 10px);
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #333;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 3px;
                    white-space: nowrap;
                    opacity: 0;
                    transition: opacity 0.3s;
                    pointer-events: none;
                    z-index: 1;
                    font-size: 12px;
                }

                &:hover::after {
                    opacity: 1;
                }
            }
        }

        .deliverystaff_sidebar_sign_out_icon {
            text-align: center;
            padding: 10px 0;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;

            i {
                font-size: 16px;
            }

            &:hover {
                background-color: #e0e0e0;
            }

            &::after {
                content: "Sign Out";
                position: absolute;
                left: calc(100% + 10px);
                top: 50%;
                transform: translateY(-50%);
                background-color: #333;
                color: #fff;
                padding: 5px 10px;
                border-radius: 3px;
                white-space: nowrap;
                opacity: 0;
                transition: opacity 0.3s;
                pointer-events: none;
                z-index: 1;
                font-size: 12px;
            }

            &:hover::after {
                opacity: 1;
            }
        }
        .deliverystaff_sidebar_edit_profile {
            text-align: center;
            padding: 10px 0;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;

            i {
                font-size: 16px;
            }

            &:hover {
                background-color: #e0e0e0;
            }

            &::after {
                content: "Sign Out";
                position: absolute;
                left: calc(100% + 10px);
                top: 50%;
                transform: translateY(-50%);
                background-color: #333;
                color: #fff;
                padding: 5px 10px;
                border-radius: 3px;
                white-space: nowrap;
                opacity: 0;
                transition: opacity 0.3s;
                pointer-events: none;
                z-index: 1;
                font-size: 12px;
            }

            &:hover::after {
                opacity: 1;
            }
        }
    }

    .deliverystaff_sidebar_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px 16px 40px 16px;
        height: 60px;
        background-color: #fafafb;

        .deliverystaff_sidebar_profile {
            display: flex;
            flex-direction: column;

            &.hidden {
                display: none;
            }

            .deliverystaff_sidebar_full_name {
                font-weight: bold;
                margin: 15px 0 5px 15px;
            }

            .deliverystaff_sidebar_role {
                font-size: 14px;
                color: #888;
                margin-left: 15px;
            }

        }

        .toggle-button {
            font-size: 24px;
            cursor: pointer;
            text-align: center;
        }
    }

    .deliverystaff_sidebar_content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .deliverystaff_sidebar_profile {
            text-align: left;
            margin-bottom: 20px;

            .deliverystaff_sidebar_full_name {
                font-weight: bold;
                margin-bottom: 5px;
            }

            .deliverystaff_sidebar_role {
                font-size: 14px;
                color: #888;
            }
        }

        .deliverystaff_sidebar_menu {
            list-style: none;
            padding: 0;
            margin: 0;

            .deliverystaff_sidebar_menu_item {
                display: flex;
                align-items: center;
                padding: 10px;
                cursor: pointer;

                .fa-chart-bar {
                    font-size: 17px;
                    margin-right: 12px;
                    margin-left: 23px;
                }

                .fa-users {
                    font-size: 16px;
                    margin-right: 11px;
                    margin-left: 21px;
                }

                .fa-box-open {
                    font-size: 16px;
                    margin-right: 10px;
                    margin-left: 21px;
                }

                .fa-gem {
                    font-size: 17px;
                    margin-right: 10px;
                    margin-left: 23px;
                }

                .fa-ring {
                    font-size: 17px;
                    margin-right: 10px;
                    margin-left: 23px;
                }

                span {
                    font-size: 16px;
                }

                &:hover,
                &.selected {
                    background-color: #e0e0e0;
                }
            }
        }
        .deliverystaff_sidebar_edit_profile{
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 10px 10px 10px;

            .fa-user {
                font-size: 17px;
                margin-right: 10px;
                margin-left: 23px;
                margin-bottom: -2px;
            }

            span {
                font-size: 16px;
            }

            &:hover {
                background-color: #e0e0e0;
            }
        }
        .deliverystaff_sidebar_sign_out {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 20px;
            padding: 10px 10px 10px 10px;

            .fa-sign-out-alt {
                font-size: 17px;
                margin-right: 10px;
                margin-left: 23px;
                margin-bottom: -2px;
            }

            span {
                font-size: 16px;
            }

            &:hover {
                background-color: #e0e0e0;
            }
        }
    }

    .fa-angle-double-right::before {
        margin-left: -13px;
    }

    .fa-chart-bar::before {
        font-size: 17px;
    }

    .fa-users::before {
        font-size: 16px;
    }

    .fa-box-open::before {
        font-size: 16px;
    }

    .fa-gem::before {
        font-size: 17px;
    }

    .fa-ring::before {
        font-size: 17px;
    }

    .fa-sign-out-alt::before {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .manager_side_bar_line2 {
        margin: 0 0 -155px 0;
    }
}

//deli_noti section
.deli_notification_icon {
    position: relative;
    flex-direction: column;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding-top: 3px;
    margin-left: 10px;
}
.deli_notification_badge {
    position: absolute;
    top: 15%;
    right: -86%;
    background-color: #f8dcce;
    color: #1c1c1c;
    border-radius: 50%;
    padding: 2px 6px 2px 5px;
    max-width: 17px;
    max-height: 17px;
    font-size: 9px;
    width: 17px;
    height: 17px;
}

.deli_noti_dropdown_menu {
    position: absolute;
    top: 43px;
    left: -95px;
    width: 320px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    white-space: normal;
    border: 1px solid rgb(195, 195, 195);
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    padding-bottom: 10px;
}

.deli_noti_header_wrapper {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 15px 10px 15px;
    font-size: 17px;
}

.deli_noti_dropdown_menu .deli_noti_header {
    font-weight: bold;
    text-align: start;
    width: 100%;
}

.deli_noti_dropdown_menu .deli_noti_header_view,
.deli_noti_dropdown_menu .fa-arrow-right {
    text-align: end;
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
    margin-right: 3px;
    margin-top: 2px;
}

.deli_noti_dropdown_menu .fa-arrow-right {
    margin-left: 5px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.deli_noti_dropdown_menu .deli_noti_header_view:hover {
    color: #797979;
}

.deli_noti_header_view:hover i {
    transform: translateX(7px);
}

.deli_noti_dropdown_menu .deli_noti_item {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.deli_noti_dropdown_menu .deli_noti_item p {
    margin: 0;
    font-size: 14px;
    color: #1c1c1c;
}

.deli_noti_dropdown_menu .deli_noti_item span {
    font-size: 12px;
    color: #797979;
}

.deli_noti_item {
    position: relative;
}

.deli_noti_date p {
    position: absolute;
    bottom: 9px;
    right: 15px;
    font-size: 12px !important;
    color: #797979 !important;
}


.deli_each_noti {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 5px;
    cursor: pointer;
    padding: 7px 15px 20px 15px;
}

.deli_each_noti:hover {
    background-color: #f4f4f4;
}


.deli_noti_title,
.deli_noti_description {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: block;
}


.deli_noti_title {
    font-weight: bold;
    font-size: 14px;
    text-align: start;
    max-width: 100%;
}

.deli_noti_description {
    font-size: 13px;
    margin: 2px 0;
    color: #797979;
    text-align: start;
    max-width: 100%;
}

.deli_noti_time {
    font-size: 12px;
    color: #797979;
    text-align: end;
}
.no_notification {
    text-align: left;
    margin: 20px 0 -5px 15px;
}

.deli_noti_view_more_wrapper {
    width: 100%;
    padding: 20px 20px 15px 20px;
}

.deli_noti_view_more_button {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    font-size: 15px;
    background-color: #1c1c1c;
    color: white;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.deli_noti_view_more_button:hover {
    background-color: #797979;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style the scrollbar for Chrome, Safari and Opera */
.deli_notifications_container::-webkit-scrollbar {
    width: 10px; /* Scrollbar width */
}

.deli_notifications_container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

.deli_notifications_container::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
}

.deli_notifications_container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
}

/* Style the scrollbar for Firefox */
.deli_notifications_container {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #888 #f1f1f1; /* Handle and track color */
}

/* Style the scrollbar for IE and Edge */
.deli_notifications_container {
    -ms-overflow-style: -ms-autohiding-scrollbar; /* Auto-hiding scrollbar */
}