input {
    height: 30px;
}

:placeholder-shown {
    font-size: 70%;
}

.rp_main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 30px 92px;
}

.rp_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.rp_left_side {
    padding: 130px 50px 50px 50px;
}

.rp_right_side {
    padding: 0 !important;
}

.rp_right_image {
    height: 90vh;
    width: 100%;
    object-fit: cover;
    margin-bottom: -6px;
}

.rp_password_section {
    margin-bottom: 5%;
}

.rp_button {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    margin-top: 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.rp_button:hover {
    background-color: #797979;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.reset_password_title {
    text-align: center;
    font-weight: bold;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.rp_password_label {
    font-weight: bold;
}

.rp_password_section input,
.rp_re_password_section input {
    background-color: #eee;
    border: none;
}

.rp_password_section input,
.rp_re_password_section input {
    background-color: #f3f4f6;
    border: none;
}

.rp_password_section input:focus,
.rp_re_password_section input:focus {
    background-color: #f3f4f6;
    border: none;
    outline: none;
    box-shadow: none;
}

.rp_password_eye,
.rp_re_password_eye {
    position: absolute;
    top: 49%;
    right: 10px;
    cursor: pointer;
}

.swal-button {
    background-color: #1c1c1c;
}

.swal-button:focus {
    box-shadow: none;
}

.swal-button:hover {
    background-color: #797979 !important;
}

/* Slider customization */
.slick-slider {
    width: 100%;
}

.slick-dots {
    bottom: 10px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #fff;
}

.slick-prev:before,
.slick-next:before {
    color: #fff;
    left: 50px;
}

.login_slider_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    font-size: 24px;
    color: white;
}

.slick-prev {
    left: 10px;
    z-index: 2;
    top: 53%;
}

.slick-next {
    right: 8px;
    z-index: 2;
    top: 53%;
}

.login_left_arrow,
.login_right_arrow {
    color: white;
    font-size: 23px;
}
