.sub_nav {
    font-size: 13px;
    white-space: nowrap;
    margin: 11px 0 -4px 0;
    text-align: center;
}

.nav-item {
    margin: 0 10px;
    color: #565e6c;
    margin-left: 14px;
    cursor: pointer;
}

.nav-item:hover{
    color: #1c1c1c;
}

.sub_nav_hr{
    color: gray;
    margin-bottom: 0;
}