/* Main image */
.diamond_price_main_image {
    display: flex;
    align-items: center;
    padding: 30px 20% 30px 10%;
    background: url('../../assets/img/diamondPrice3.webp') no-repeat center center;
    background-size: cover;
    height: 400px;
    color: #1c1c1c;
    opacity: 0;
    transition: opacity 1.4s ease-in-out, transform 1.2s ease-in-out;
    margin-top: -20px;
}

.diamond_price_main_image.visible {
    opacity: 1;
    transform: translateY(20px);
}

.diamond_price_content {
    max-width: 50%;
    text-align: left;
}

.diamond_price_content h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.diamond_price_content p {
    font-size: 16px;
    color: #1c1c1c;
    margin-bottom: 20px;
}

/* Title */
.diamond_price_title_container {
    text-align: center;
    margin: 90px 0 10px 0;
    padding: 20px 10%;
}

.diamond_price_title {
    font-size: 28px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 10px;
}

.shell_price_title {
    font-size: 28px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 10px;
}


.diamond_price_description {
    font-size: 16px;
}

/* Price form */
.diamond_price_form_wrapper {
    text-align: center;
    padding: 0 10%;
    margin-bottom: 100px;
    margin-top: -20px;
}

.diamond_price_form_title {
    font-size: 24px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 20px;
}

.diamond_price_form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_row {
    display: flex;
    gap: 40px;
    width: 100%;
    justify-content: center;
}

.diamond_price_form .MuiFormControl-root {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    padding: 0 10px !important;
    background-color: white;
}

.calculate_btn {
    margin-top: 20px !important;
    color: white;
    background-color: #1c1c1c !important;
    width: 300px;
    padding: 10px 0 !important;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.calculate_btn:hover {
    background-color: #797979 !important;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.diamond_price_result {
    margin-top: 20px;
    font-size: 20px;
    color: #1c1c1c;
    font-weight: bold;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: center;
}

/* Middle content */
.diamond_price_middle_content {
    padding: 0 10%;
    text-align: center;
}

.diamond_price_image_wrapper {
    margin: 20px 0;
}

.diamond_price_mid_image {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.diamond_price_mid_image3 {
    width: 100%;
    height: 100%;
    border: 1px solid #cac9cd;
    border-radius: 5px;
}

.diamond_price_mid_content {
    font-size: 16px;
    margin: 20px 0 60px 0;
}

.last_mid_content {
    font-style: italic;
}

/* Last content */
.diamond_price_last_content {
    padding: 0 10%;
}

.diamond_price_last_content_description {
    border-left: 5px solid #1c1c1c;
    padding-left: 20px;
    margin: 50px 0 150px 0;
}

.diamond_price_last_content a {
    text-decoration: none;
    color: #1c1c1c;
}

.diamond_price_last_content a:hover {
    color: #797979 !important;
}

/* Filter and Table */
.diamond_price_filter {
    display: flex;
    align-items: center;
    text-align: center;
}

.diamond_price_filter .MuiFormControl-root {
    width: 150px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.diamond_price_table_wrapper {
    margin: 0 0 0 0;
    overflow-x: auto;
    max-height: 550px;
    overflow-y: auto;
    position: relative;
}

.diamond_price_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.diamond_price_table th {
    background-color: #1c1c1c;
    color: white;
    text-align: left;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.diamond_price_table_data {
    padding: 10px;
    border: none;
}

.diamond_price_table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.diamond_price_table th,
.diamond_price_table td {
    text-align: center;
}

.diamond_price_table tr:hover {
    background-color: #f4b798;
    color: white;
    cursor: pointer;
}

.diamond_price_table th,
.diamond_price_table td {
    font-size: 14px;
    padding: 15px;
}

.filter_group_remove {
    margin-left: 20px;
    color: #1c1c1c;
    border: 1px solid #1c1c1c;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    color: #1c1c1c !important;
    height: 40px !important;
    border: 1px solid #1c1c1c !important;
    margin-bottom: 5px !important;
}

.shell_price_title_container {
    text-align: center;
    margin: 90px 0 10px 0;
    padding: 20px 10%;
}

.shell_price_filter {
    display: flex;
    align-items: center;
    text-align: center;
}

.shell_price_filter .MuiFormControl-root {
    width: 150px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.shell_price_table_wrapper {
    margin: 0 0 0 0;
    overflow-x: auto;
    max-height: 550px;
    overflow-y: auto;
    position: relative;
}

.shell_price_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.shell_price_table th {
    background-color: #1c1c1c;
    color: white;
    text-align: left;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.shell_price_table_data {
    padding: 10px;
    border: none;
}

.shell_price_table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.shell_price_table th,
.shell_price_table td {
    text-align: center;
}

.shell_price_table tr:hover {
    background-color: #f4b798;
    color: white;
}

.shell_price_table th,
.shell_price_table td {
    font-size: 14px;
    padding: 15px;
}

.css-12mxrte {
    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
    width: 750px !important;
    height: 380px !important;
    padding: 80px 60px 40px 40px !important;
}

.css-12mxrte span {
    font-size: 40px !important;
    right: 30px !important;
    color: #1c1c1c;
}

.css-12mxrte span:hover {
    color: #797979;
}

.MuiBox-root {
    position: relative;
}

.MuiBox-root h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.MuiBox-root p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.diamond_modal h2 {
    font-size: 30px;
}

.diamond_modal p {
    font-size: 20px;
    color: #797979;

}

.diamond_modal .diamond_modal_price {
    margin-top: 40px;
}

.diamond_modal Button {
    background-color: #1c1c1c;
    color: white;
    border: none;
    padding: 7px 55px;
    cursor: pointer;
    border-radius: 30px;
    font-size: 13px;
    // margin-right: -180px;
    margin-top: 5px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}


.diamond_modal Button:hover {
    background-color: #797979;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.diamond_modal_content {
    display: flex;
    align-items: center;
}

.diamond_modal_text {
    flex: 1;
    padding-right: 50px;
}

.DiamondPrice {
    .product_cards_title_container {
        text-align: center;
        margin: 60px 0 0 0;
        padding: 20px 5%;
    }

    .product_cards_title {
        font-size: 28px;
        font-weight: bold;
        color: #1c1c1c;
        margin-bottom: 10px;
    }

    .product_cards_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 40px 5%;
        justify-content: center;
        text-align: center;
    }

    .product_card {
        position: relative;
        flex: 0 0 236px;
        background-color: white;
        border: 1px solid #e0e0e0;
        padding: 0 0 10px 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        height: 340px;
        overflow: hidden;
        cursor: pointer;
    }

    .product_card img {
        width: 100%;
        height: 220px;
        margin-bottom: 25px;
        transition: transform 0.3s ease-in-out;
        transform-origin: bottom;
    }

    .product_card:hover img {
        transform: scale(1.1);
    }

    .product_card h6 {
        font-size: 15px;
        margin-bottom: 10px;
        transition: transform 0.3s ease-in-out;
        margin-top: 20px;
    }

    .product_card:hover h6 {
        transform: translateY(-5px);
    }

    .product_card .product_card_price {
        font-size: 16px;
        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
        position: relative;
        z-index: 2;
        font-weight: bold;
        margin-top: 17px;
    }

    .product_card:hover .product_card_price {
        color: white;
        transform: translateY(-8px);
    }

    .product_card::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background-color: #1c1c1c;
        transition: height 0.3s ease-in-out, transform 0.3s ease-in-out;
        z-index: 1;
    }

    .product_card:hover::after {
        height: 35px;
        transform: translateY(-18px);
    }

    .product_card_detail {
        font-size: 13px;
        color: #797979;
        margin: -5px 0 -15px 0;
        transition: transform 0.3s ease-in-out;
    }

    .product_card:hover .product_card_detail {
        transform: translateY(-5px);
    }

    .product_view_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -200px;
        left: 170px;
        font-size: 17px;
        color: #1c1c1c;
        background-color: white;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        padding: 1px 0.5px 0 0;
        transition: opacity 0.3s ease-in-out, transform 0.4s ease-in-out;
        opacity: 0;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        z-index: 5;
        cursor: pointer;
    }

    .product_card:hover .product_view_icon {
        opacity: 1;
        transform: translateY(-25px);
    }

    .product_view_icon_wrapper {
        position: relative;
    }

    .product_view_icon_wrapper[data-tooltip]::after {
        content: attr(data-tooltip);
        position: absolute;
        left: calc(100% + -150px);
        top: -215px;
        background-color: #1c1c1c;
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
        font-size: 12px;
    }

    .product_view_icon_wrapper:hover::after {
        opacity: 1;
    }
}