// Shape 
.s_diamond_shape_container {
    display: flex;
    justify-content: center;
    margin: 30px 2% -10px 2%;
}

.s_diamond_shape_grid {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.s_diamond_shape_column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    margin: 0 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.s_diamond_shape_column:hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    margin: 0 5px;
    transition: transform 0.3s ease;
}

.s_diamond_shape_column a {
    text-decoration: none;
}

.s_diamond_shape_image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.s_diamond_shape_name {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #333;
    transition: transform 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.s_diamond_shape_column:hover .s_diamond_shape_image {
    transform: translateY(-5px) scale(1.1) rotate(5deg);
}

.s_diamond_shape_column:hover .s_diamond_shape_name {
    transform: translateY(-5px) scale(1.2);
    color: #1c1c1c; 
    font-weight: bold;
}


// End shape 

.shape_filters_and_products {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
}

.shape_filters_products {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: -20px;

    .shape_filter_group {
        margin: 0 20px;
        width: 120px;

        label {
            margin-bottom: 5px;
        }

        .MuiFormControl-root {
            width: 90%;
        }
    }

    .shape_filter_group_sort{
        margin: 0 20px;
        width: 200px;
    }

    .shape_filter_group_remove{
        margin: 0 20px;
        color: #1c1c1c;
        border: 1px solid #1c1c1c;
    }
}

.search_product_list_note_wrapper{
    text-align: center;
    font-style: italic;
    margin-bottom: -25px;
    margin-top: 10px;
}