//Main title
.faqs_banner_container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1.4s ease-in-out, transform 1.2s ease-in-out;
  margin-top: -20px;
}

.faqs_banner_container.visible {
  opacity: 1;
  transform: translateY(20px);
}

.faqs_banner_content h2 {
  color: white;
  font-weight: bold;
}

// End main title 

// FAQ Search Section
.faqs_search_section {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: -30px;
  position: relative;
}

.faqs_search_input {
  width: 50%;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #1c1c1c;
  font-size: 16px;
  outline: none;
  line-height: 100px;
}

.faqs_search_button {
  height: 40px;
  width: 50px;
  border: none;
  background-color: #1c1c1c;
  color: white;
  font-size: 16px;
}


// Main FAQS 
.main_faqs_container {
  padding: 20px 70px;
  margin: 0 0 20px 0;
  border-radius: 8px;
}

.main_faqs_title {
  text-align: center;
  font-size: 26px;
  color: #1c1c1c;
  margin-bottom: 10px;
  font-weight: bold;
}

.main_faqs_number {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin-bottom: 30px;
}

.main_faqs_question {
  cursor: pointer;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.main_faqs_sub_title {
  font-size: 18px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_faqs_description {
  font-size: 16px;
  color: #444;
  padding: 10px 0 0 0;
}

.main_faqs_line {
  margin-top: 10px;
}

// End Main FAQS 

// Still need help
.faqs_help_container {
  color: white;
  margin-bottom: 70px;
  padding: 0 0 0 0;

  .row{
    margin-left: 20px;
    margin-right: 20px;
    background-color: #f4f2ee;
  }
}

.faqs_help_content {
  padding: 8.5% 50px 40px 70px;
}

.faqs_help_title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1c1c1c;
}

.faqs_help_description {
  margin-bottom: 20px;
  color: #1c1c1c;
}

.faqs_help_image{
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;

}

.faqs_help_image img {
  width: 100%;
  height: 100%;
}

.faqs_help_btn {
  background-color: #1c1c1c;
  color: white;
  border: none;
  padding: 7px 55px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 13px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.faqs_help_btn:hover {
  background-color: #797979;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

// End still need help

.faqs_view_more_button {
  color: #1c1c1c;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 5px;
  transition: color 0.3s;
  font-size: 17px;
  cursor: pointer;
  margin: 50px auto 0 auto;
  display: block;
}

.faqs_view_more_button:hover {
  color: #797979;
}