.manager_statitic_all_container {
    display: flex;
    width: 100%;
}

.manager_statitic_content {
    flex-grow: 1;
    padding: 10px 8% 0 8%;
}
.manager_statistic_smaller_content{
    background-color: #efe2e2;
}
.manager_manage_diamond_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.manager_manage_statistic_title{
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
}

.manager_header_line {
    width: 150%;
    margin-left: -15%;
    z-index: 1;
    color: gray;
}

.manager_manage_diamond_header .manager_manage_diamond_logo {
    height: 60px;
    width: 120px;
    max-width: 120px;
    margin-bottom: 5px;
}

.manager_manage_diamond_header .manager_manage_diamond_search_section {
    display: flex;
    align-items: center;
    //background-color: white;
}
.manager_manage_diamond_header .manager_manage_diamond_search_section input {
    background-color: #f3f4f6;
    border: none;
    width: 220px;
    height: 28px;
}

.manager_manage_diamond_header .manager_manage_diamond_search_section input:focus {
    background-color: #f3f4f6;
    border: none;
    outline: none;
    box-shadow: none;
    width: 220px;
}

.manager_manage_diamond_header .manager_manage_diamond_search_bar {
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 10px;
} 

.manager_manage_report_div1_wrapper{
    width: 100%;
}

.manager_manage_report_div{
    background: white;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: 0 4px 12px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 12px;
    margin-right: 12px;
    margin-left: 12px;
    width: 170px;
    padding: 10px;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.manager_manage_report_div:hover{
    background-color: #f3f4f6;
    // border: 1px solid black;
   // cursor: pointer;
}
.manager_manage_display_chart{
    flex: 2;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}
.manager_manage_display_chart:hover{
    border: 1px solid black;
    cursor: pointer;
}
.manager_statis_input_date{
    border: 1px solid grey;
    border-radius: 6px;
    padding-left: 10px;
}

.manager_manage_report_div1{
    text-align: center;
}