//modify sidebar
.ss_manage_order_all_container {
    display: flex;
    width: 100%;
}

.ss_manage_order_content {
    flex-grow: 1;
    padding: 15px 6% 6% 8%;
}

//modify sidebar
.ss_sort_order_status{
    display: flex;
}
.ss_header_pagination_list{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.css-1sazv7p-MuiStack-root{
    margin-top: 25px;
}