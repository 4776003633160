.Blog .blog_nav_bar_container .row>*{
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.blog_main_container {
    padding: 40px 5% 0 5%;
}

.blog_logo {
    display: flex;
    justify-content: center;
}

.blog_logo img {
    width: 20%;
    margin: 20px 0 10px 0;
}

.blog_popular_title {
    position: relative;
    text-align: center;
    margin: 15px 0 20px 0;
    padding: 0 10px;
}

// Popular blog

.blog_popular_title h4 {
    display: inline-block;
    position: relative;
    z-index: 1;
    background: white;
    padding: 0 20px;
    font-size: 22px;
}

.blog_popular_title::before,
.blog_popular_title::after {
    content: "";
    position: absolute;
    top: 40%;
    width: 35%;
    height: 2px;
    background-color: #f4b798;
    z-index: 0;
}

.blog_popular_title::before {
    left: 15%;
}

.blog_popular_title::after {
    right: 15%;
}

.blog_card {
    border: none;
    border-radius: 0;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    height: 450px;
    margin: 0 5px -10px 5px;
    padding-bottom: 0;
    position: relative;
}

.blog_card_button {
    text-align: center;
}

.blog_image {
    height: 330px;
    object-fit: cover;
    border-radius: 0;
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.blog_card:hover .blog_image {
    transform: scale(1.1) translateY(-4%);
    filter: brightness(80%);
}

.blog_card:hover .blog_title {
    color: #797979;
}

.blog_read_this_button_container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.9s ease-in, opacity 0.1s ease-out;
}

.blog_card:hover .blog_read_this_button_container {
    opacity: 1;
}

.blog_read_this_button {
    background-color: white;
    color: #1c1c1c;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    transform: translateY(100%);
    width: 90%;
}


.blog_read_this_button:hover {
    background-color: rgb(225, 225, 225);
    color: #1c1c1c;
}


.blog_card:hover .blog_read_this_button {
    transform: translateY(-320%);
}

.blog_title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

// Recycle

.blog_recycle_container {
    background-color: #f4f2ee;
    padding: 40px 5%;
    margin: -10px 0 25px 0;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }

    .blog_recycle_column {
        text-align: center;
        color: #1c1c1c;
        border-right: 1px solid #1c1c1c;
        border-left: 1px solid #1c1c1c;
        padding: 0 15px;

        &:first-child {
            border-left: 1.4px solid #1c1c1c;

        }

        &:last-child {
            border-right: 1.4px solid #1c1c1c;
        }

        .blog_recycle_title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
        }
    }
}

.blog_nav_item_wrapper{
    display: flex;
}


// Blog nav bar

.blog_nav_bar_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 45px 0;
    background-color: white;
    border-bottom: 1px solid #ddd;
    max-width: 100% !important;
}

.blog_nav_item {
    display: flex;
    align-items: center;
    margin: 0 15px;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    overflow: hidden;
}

.blog_nav_item i {
    font-size: 18px;
    margin-right: 10px;
    color: #797979;
}

.blog_nav_item span:hover {
    color: #797979;
}

.blog_nav_item:hover {
    color: #1c1c1c;
}

.blog_nav_search {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.blog_nav_search input {
    border: none;
    border-bottom: 2px solid #1c1c1c;
    padding: 5px;
    // padding-right: 30px;
    // margin-right: 5px;
    outline: none;
    width: 250px;
}

.blog_nav_search i {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: #1c1c1c;
}

.blog_nav_item span {
    font-size: 15px;
    color: #1c1c1c;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: color 0.3s;
}

.blog_nav_item span::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1.5px;
    background-color: #797979;
    transition: width 0.6s ease, left 0.6s ease, opacity 0.5s ease-in;
    opacity: 0;
}

.blog_nav_item span:hover::before {
    width: 100%;
    left: 0;
    opacity: 1;
}

.blog_nav_item:hover {
    color: #797979;
}


// Small blog

.remaining_blogs_container {
    padding: 20px 5% 0 5%;
    margin-bottom: 50px;
    margin-top: 30px;
}

.small_blog_card {
    border: none;
    border-radius: 0;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    height: 300px;
    margin: 0 5px -20px 5px;
    cursor: pointer;
}

.small_blog_card_button {
    text-align: center;
}

.small_blog_card:hover {
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.small_blog_image {
    height: 180px;
    object-fit: cover;
    border-radius: 0;
}

.small_blog_title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.small_blog_read_more {
    color: #797979;
    text-decoration: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-size: 13px;
}

.small_blog_card:hover .small_blog_read_more {
    color: #1c1c1c;
    text-decoration: none;
    transform: translateY(-4px);
}

.small_blog_card:hover .small_blog_read_more i {
    transform: translateX(7px);
}

.small_blog_read_more i {
    margin-left: 5px;
    font-size: 11px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

// See more

.blog_see_more_container {
    text-align: center;
    margin-top: -65px;
}

.blog_see_more_button {
    color: #1c1c1c;
    background: none;
    border: none;
    text-decoration: underline;
    padding: 5px;
    transition: color 0.3s;
    font-size: 17px;
}

.blog_see_more_button:hover {
    color: #797979;
}

//Mission
.blog_mission_container {
    position: relative;
    background-color: #f4f2ee;
    margin: 50px 0 85px 0;
    // border-bottom: 86px solid #f8dcce;
    border-left: 70px solid #f8dcce;
    border-right: 70px solid white;
}

.blog_mission_text {
    position: absolute;
    top: 94%;
    left: 17%;
    font-size: 100px;
    font-weight: 600;
    color: white;
    z-index: 10;
    transform-origin: left top 0;
    transition: transform 0.7s ease-in-out;
}

.blog_mission_white {
    position: absolute;
    top: 100%;
    right: -10%;
    font-size: 70px;
    font-weight: bold;
    color: white;
    background-color: white;
    transform-origin: left top 0;
    padding-left: 12%;
}

.blog_mission_image {
    width: 100%;
    height: 400px;
}

.blog_mission_title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.blog_mission_subtitle {
    font-size: 17px;
    margin-bottom: 40px;
}

.blog_mission_features {
    display: flex;
    justify-content: space-between;
}

.blog_mission_icon {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

.blog_mission_feature_text {
    font-size: 16px;
    font-weight: 600;
}

.blog_mission_right {
    text-align: center;
    padding: 20px 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blog_mission_icon_wrap {
    margin: 0 0;
}

.blog_inspired_title {
    position: relative;
    text-align: center;
}

.blog_inspired_title h4 {
    font-size: 22px;
    color: #1c1c1c;
}

.blog_inspired_title p {
    color: #797979;
}

.blog_inspired_grid {
    display: flex;
    justify-content: center;
}