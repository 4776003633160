body {
    overflow-x: hidden;
}

.top_announcement {
    background-color: #1c1c1c;
    color: white;
    text-align: center;
    padding: 5px 0;
    font-size: 12px;
    margin-bottom: 5px;
}

.header {
    position: relative;
    top: 0;
    z-index: 2;
    width: 100%;
    background-color: white;
    border-top: 1px solid white;
}

.navbar-nav .nav-item .home,
.navbar-nav .nav-item .diamond,
.navbar-nav .nav-item .wedding,
.navbar-nav .nav-item .education,
.navbar-nav .nav-item .introduce,
.navbar-nav .nav-item .contact,
.navbar-nav .nav-item .price_list {
    text-transform: uppercase;
    padding: 10px 20px 16px 20px;
    color: #1c1c1c;
    font-weight: bold;
}

:placeholder-shown {
    font-size: 70%;
    padding: 5px 10px 5px 10px;
}

.top_header {
    background-color: white;
    border: none;
}

.contact_info i {
    margin: 5px 10px 5px 5px;
}

.contact_info a {
    text-decoration: none;
    color: #1c1c1c !important;
}

.contact_info a:hover {
    color: #797979 !important;
}

.contact_phone {
    margin: 13px 10px 0 25px;
    font-size: 12px;
}

.contact_address {
    margin: 0 10px 10px 25px;
    font-size: 12px;
}

.logo_container{
    cursor: pointer;
}

.logo {
    width: 80%;
    margin: 8px 0 5px 0;
    cursor: pointer;
}

.header_icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    // margin-right: 30px;
}

.header_icons {
    .fa-bell {
        font-size: 19px;
        color: #1c1c1c;
        cursor: pointer;
        transition: color 0.3s ease;
        margin-left: 25px;
        margin-top: 4px;
        margin-right: 30px;

        &:hover {
            color: #797979;
        }
    }

    .icon_cart {
        font-size: 19px;
        color: #1c1c1c;
        margin: 0 8px 0 25px;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
            color: #797979;
        }
    }

    .icon_arrow {
        font-size: 10px;
        cursor: pointer;
        transition: transform 0.3s ease;
        margin-left: 4px;
    }

    .icon_account {
        color: #1c1c1c;
        font-size: 17px;
        margin-right: 25px;

        &:hover {
            color: #797979;
        }
    }
}

.header_icons i {
    font-size: 18px;
    cursor: pointer;
}

.navbar-nav .nav-item .nav-link {
    font-size: 13.5px;
    padding-bottom: 0;
}

.header_icons .cart_icon {
    position: relative;
}

.cart_badge {
    position: absolute;
    top: -21%;
    right: -9%;
    background-color: #f8dcce;
    color: #1c1c1c;
    border-radius: 50%;
    padding: 2px 6px 2px 3px;
    font-size: 9px;
    max-width: 17px;
    max-height: 17px;
    width: 17px;
    height: 17px;
}

//Noti section
.notification_icon {
    position: relative;
}

.notification_badge {
    position: absolute;
    top: -17%;
    right: 26%;
    background-color: #f8dcce;
    color: #1c1c1c;
    border-radius: 50%;
    padding: 2px 6px 2px 3px;
    max-width: 17px;
    max-height: 17px;
    font-size: 9px;
    width: 17px;
    height: 17px;
}

.noti_dropdown_menu {
    position: absolute;
    top: 26.5px;
    right: -33px;
    width: 382px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    white-space: normal;
    border: 1px solid rgb(195, 195, 195);
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    padding-bottom: 10px;
}

.noti_header_wrapper {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 15px 10px 15px;
    font-size: 17px;
}

.noti_dropdown_menu .noti_header {
    font-weight: bold;
    text-align: start;
    width: 100%;
}

.noti_dropdown_menu .noti_header_view,
.noti_dropdown_menu .fa-arrow-right {
    text-align: end;
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
    margin-right: 3px;
    margin-top: 2px;
}

.noti_dropdown_menu .fa-arrow-right {
    margin-left: 5px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.noti_dropdown_menu .noti_header_view:hover {
    color: #797979;
}

.noti_header_view:hover i {
    transform: translateX(7px);
}

.noti_dropdown_menu .noti_item {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.noti_dropdown_menu .noti_item p {
    margin: 0;
    font-size: 14px;
    color: #1c1c1c;
}

.noti_dropdown_menu .noti_item span {
    font-size: 12px;
    color: #797979;
}

.noti_item {
    position: relative;
}

.noti_date p {
    position: absolute;
    bottom: 9px;
    right: 15px;
    font-size: 12px !important;
    color: #797979 !important;
}


.each_noti {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 5px;
    cursor: pointer;
    padding: 7px 15px 10px 15px;
}

.each_noti:hover {
    background-color: #f4f4f4;
}


.noti_title,
.noti_description {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: block;
}


.noti_title {
    font-weight: bold;
    font-size: 14px;
    text-align: start;
    max-width: 100%;
}

.noti_description {
    font-size: 13px;
    margin: 2px 0;
    color: #797979;
    text-align: start;
    max-width: 100%;
}

.noti_time {
    font-size: 12px;
    color: #797979;
    text-align: end;
}


//Nav item animation

.navbar-nav .nav-item .nav-link {
    font-size: 13.5px;
    position: relative;
    overflow: hidden;
}

.navbar-nav .nav-item .nav-link::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 20px;
    width: 0;
    border: 1px solid #797979;
    transition: width 0.6s ease, left 0.6s ease, opacity 0.5s ease-in, ;
    opacity: 0;
}

.navbar-nav .nav-item .nav-link:hover::before {
    width: calc(100% - 40px);
    left: 20px;
    opacity: 1;
}

.navbar-nav .nav-item .header_spe_nav_link:hover::before {
    width: calc(100% - 57px);
    left: 20px;
    opacity: 1;
}

.navbar-nav .nav-item .header_spe_nav_link:hover::before,
.diamond_dropdown_section:hover .nav-link::before,
.wedding_dropdown_section:hover .nav-link::before,
.diamond_dropdown_menu:hover~.nav-link::before,
.wedding_dropdown_menu:hover~.nav-link::before {
    width: calc(100% - 57px);
    left: 20px;
    opacity: 1;
}

.navbar-nav .nav-item .header_spe_nav_link:hover::before,
.diamond_dropdown_section:hover .nav-link,
.wedding_dropdown_section:hover .nav-link,
.diamond_dropdown_menu:hover~.nav-link,
.wedding_dropdown_menu:hover~.nav-link {
    color: #797979;
}

//Navbar
.navbar {
    border-bottom: 1px solid #e0e0e0;
    background-color: white;
    margin: -2px -5px -5px -5px;
}

.search_section input {
    background-color: #ededed;
    border: none;
    margin-right: 10px;
    width: 200px;
    height: 28px;
}

.search_section input:focus {
    background-color: #ededed;
    border: none;
    outline: none;
    box-shadow: none;
    width: 200px;
}

.search_bar_container {
    position: relative;
    display: inline-block;
}

.search_icon {
    position: absolute;
    top: 51%;
    left: 10px;
    transform: translateY(-50%);
    color: gray;
    font-size: 13px !important;
}

.search_bar {
    padding-left: 30px;
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
    line-height: 35px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.account_dropdown_section {
    position: relative;
    display: inline-block;
}

.account_dropdown_section .dropdown-menu {
    display: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.6s ease, transform 0.5s ease;
}

.account_dropdown_section:hover .dropdown-menu {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.account_dropdown_section:hover .icon_arrow {
    transform: rotate(-180deg);
}

.account_dropdown_section .dropdown-menu:hover {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.account_dropdown_menu {
    top: 25px;
    left: -227.1px;
    width: 260px;
    border-radius: 0;
    white-space: nowrap;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.account_dropdown_section.open .fa-user{
    color: #797979;
}

.notification_icon.open  .fa-bell{
    color: #797979;
}

.account_dropdown_menu .fa-clipboard {
    font-size: 16px;
    margin-right: 6px;
    margin-left: 1px;
}

.dropdown-item {
    white-space: normal;
    word-wrap: break-word;
}

.account_dropdown_menu .dropdown-item:hover i {
    color: #797979;
}

.account_dropdown_menu .dropdown-item:hover {
    color: #797979;
}


// .icon_arrow {
//     cursor: pointer;
//     transition: transform 0.3s;
//     position: relative;
//     top: -3px;
// }

.icon_arrow_diamond,
.icon_arrow_wedding {
    margin-left: 7px;
    cursor: pointer;
    transition: transform 0.3s;
    position: relative;
    top: -1.5px;
}

.adm_icon {
    margin-right: 5px;
    font-size: 130px;
}

.adm_icon:hover {
    color: #797979;
}

.account_hr_guest {
    margin: 5px 0 5px 0;
}

.account_hr1 {
    margin: -5px 0 8px 0;
}

.account_hr2 {
    margin: 12px 0 5px 0;
}

.full_name {
    font-weight: bold;
}

.point {
    font-size: 13px;
    color: #797979;
    margin-top: -23px;
}

.account_dropdown_menu i {
    color: #1c1c1c;
    font-size: 15px;
}

.account_dropdown_menu .dropdown-item:hover {
    background-color: #f4f4f4;
}

.account_dropdown_menu .full_name:hover,
.account_dropdown_menu .point:hover {
    background-color: transparent !important;
    color: inherit !important;
    cursor: default;
}


.header .fa-phone-alt,
.header .fa-map-marker-alt {
    color: #1c1c1c;
    font-size: 15px;
}


//Diamond jewelry + wedding jewelry section
.diamond_dropdown_section {
    position: relative;
    display: inline-block;
}

.diamond_dropdown_section:hover .dropdown-menu {
    display: block;
}

.diamond_dropdown_section:hover .icon_arrow_diamond {
    transform: rotate(-180deg);
}

.diamond_dropdown_section i {
    color: #797979;
    font-size: 15px;
}

.diamond_dropdown_section li:focus {
    color: #797979;
}

.diamond_dropdown_menu {
    display: none;
    flex-direction: row;
    width: 455%;
    border-radius: 0;
    left: 0;
    right: 0;
    top: 38px;
    background-color: white;
    padding: 10px 40px 15px 40px;
}

.diamond_dropdown_menu .dropdown_content {
    display: flex;
    width: 100%;
}

.diamond_dropdown_menu .dropdown_items {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
}

.diamond_dropdown_menu .dropdown_image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.diamond_dropdown_menu .dropdown_image img {
    max-height: 200px;
    width: 500px;
}

.diamond_dropdown_menu li a,
.wedding_dropdown_menu li a {
    cursor: pointer;
    color: #797979;
    display: block;
    padding: 10px;
}

.wedding_dropdown_section {
    position: relative;
    display: inline-block;
}

.wedding_dropdown_section:hover .dropdown-menu {
    display: block;
}

.wedding_dropdown_section:hover .icon_arrow_wedding {
    transform: rotate(-180deg);
}

.wedding_dropdown_section i {
    color: #565e6c;
    font-size: 15px;
}

.wedding_dropdown_section li:focus {
    color: #565e6c;
}

.wedding_dropdown_menu {
    display: none;
    flex-direction: row;
    width: 460%;
    border-radius: 0;
    left: -114%;
    right: 0;
    top: 38px;
    background-color: white;
    padding: 10px 40px 15px 40px;
}

.wedding_dropdown_menu .dropdown_content {
    display: flex;
    width: 100%;
}

.wedding_dropdown_menu .dropdown_items {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
}

.wedding_dropdown_menu .dropdown_image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wedding_dropdown_menu .dropdown_image img {
    max-height: 200px;
    width: 500px;
}

.diamond_dropdown_menu,
.wedding_dropdown_menu {
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(4px);
}

.diamond_dropdown_section:hover .dropdown-menu,
.wedding_dropdown_section:hover .dropdown-menu {
    display: flex;
    opacity: 1;
    transform: translateY(0);
}

.diamond_dropdown_section .dropdown-item,
.wedding_dropdown_section .dropdown-item {
    transition: font-weight 0.3s ease, transform 0.3s ease;
}

.diamond_dropdown_section .dropdown-item:hover,
.wedding_dropdown_section .dropdown-item:hover {
    transform: translateX(4px);
    background-color: transparent !important;
    color: #1c1c1c;
}

.diamond_dropdown_section:hover .dropdown-menu,
.wedding_dropdown_section:hover .dropdown-menu,
.diamond_dropdown_section .dropdown-menu:hover,
.wedding_dropdown_section .dropdown-menu:hover {
    display: flex;
    opacity: 1;
    transform: translateY(0);
}

.diamond_dropdown_section:hover .header_spe_nav_link,
.wedding_dropdown_section:hover .header_spe_nav_link,
.diamond_dropdown_section .dropdown-menu:hover~.header_spe_nav_link,
.wedding_dropdown_section .dropdown-menu:hover~.header_spe_nav_link {
    color: #797979;
    text-decoration: none;
}

.dropdown-item {
    cursor: pointer;
}

.no_notification {
    text-align: left;
    margin: 20px 0 -5px 15px;
}

.noti_view_more_wrapper {
    width: 100%;
    padding: 20px 20px 15px 20px;
}

.noti_view_more_button {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    font-size: 15px;
    background-color: #1c1c1c;
    color: white;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.noti_view_more_button:hover {
    background-color: #797979;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}