.ds_manage_orderdetail_all_container {
  display: flex;
  width: 100%;
}

.css-i4bv87-MuiSvgIcon-root {
  margin-right: 10px;
}

.ds_manage_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ds_manage_content_line {
  width: 150%;
  margin-left: -15%;
  z-index: 1;
  color: gray;
}

.ds_manage_content_header .ds_manage_content_logo {
  height: 60px;
  width: 120px;
  max-width: 120px;
}

.ds_manage_orderdetail_content {
  flex-grow: 1;
  padding: 15px 6% 6% 8%;
  overflow-x: hidden;
}

.ds_order_detail_p_tag {
  margin-left: 3.5%;
  margin-top: 2%;
  font-weight: 500;
  font-size: large;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ds_button_status {
  margin-top: 7px;
  margin-right: 4%;
}

.ds_detail_card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  margin: 20px 4%;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  padding: 20px;
  height: 225px;
}

.ds_detail_card:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
}

.ds_detail_card_left {
  width: 130px;
  height: 130px;
  margin-right: 20px;
}

.ds_detail_card_left img {
  width: 140%;
  height: 142%;
  object-fit: cover;
  border-radius: 8px;
}

.ds_detail_card_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 150px);
  margin-left: 50px;
}

.ds_detail_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ds_detail_card_name {
  font-size: 17px;
  font-weight: bold;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.ds_detail_card_links a {
  color: #1c1c1c;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

.ds_detail_card_links a:hover {
  text-decoration: underline;
  color: #797979;
}

.ds_detail_card_line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.ds_detail_card_item {
  flex: 1;
  margin-right: 10px;
  font-size: 14px;
}

.ds_detail_card_size {
  color: #555;
  font-size: 14px;
  margin-bottom: 0 !important;
}

.ds_detail_card_price {
  font-size: 17px;
  font-weight: bold;
  color: #333;
  text-align: end;
}

.ds_manage_content_line {
  width: 150%;
  margin-left: -15%;
  z-index: 1;
  color: gray;
}

.ds_order_detail_container {
  width: 93%;
  max-width: 1200px;
  margin: 3% auto;
  border: 0.5px solid #cccccc;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.ds_detail_confirmbutton button {
  width: 92%;
  padding: 10px 20px;
  margin-bottom: 3%;
  margin-left: 30px;
  border: 0.5px solid #1c1c1c;
  border-radius: 5px;
  background-color: #1c1c1c;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 20px;
}

.ds_detail_confirmbutton button:hover {
  border: 1.3px solid #1c1c1c;
  background-color: #797979;
}

.ds_back_button_wrapper {
  text-align: right;
  margin-right: 15px;
  margin-bottom: -15px;
}

.ds_back_button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.ds_back_button:hover {
  text-decoration: underline;
}


.salesstaff_manage_send_email_button {
  background-color: #01040c;
  margin-left: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 2px 15px;
  border: none;
  border-radius: 11px;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.salesstaff_manage_send_email_button:hover {
  background-color: #5a5a5c;
}

.salesstaff_manage_send_email_confirm_button {
  background-color: #01040c;
  color: white;
  padding: 5px 20px;
  align-items: flex-end;
  border: none;
  border-radius: 11px;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  float: right;
}

.salesstaff_manage_send_email_confirm_button:hover {
  background-color: #5a5a5c;
}

.ds_warranty_order_manage {
  display: flex;
}


.ds_manage_content_content {
  flex-grow: 1;
  padding: 15px 6% 6% 8%;
  overflow-x: hidden;
}