.ss_rich_text_page .ss_editor{
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
}
.ss_rich_text_page .ss_preview{
    position: relative;
    height: 100%;
    width: 50%;
    padding: 10px;
    border: 1px solid grey;
    overflow: hidden;   
}
.ss_rich_text_page .ss_row{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.ss_rich_text_page .ss_container{
    position: relative;
    height: 100vh;
    width: 100%;
}
.ss_rich_text_page{
    margin: 0;
    padding: 0;
}
.ss_rich_text_page .ss_editor_input{
    height: 100%;
    width: 100%;
}
.ss_rich_text_page_title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
    text-decoration: solid;
    font-weight: 600;
}
.ss_button_save_changes {
    display: inline-block;
    margin-left: 90%;
    padding: 5px 13px;
    margin-bottom: 13px;
    border: 1px solid #000; // Add a solid border
    border-radius: 10px;
    background: none; // Remove background
    color: #000; // Set text color
    cursor: pointer; // Change cursor to pointer on hover
    font-size: 16px; // Adjust font size as needed
    text-align: center;
    transition: background 0.3s ease, color 0.3s ease; // Add transition for hover effects

    &:hover {
        background: #faf5f1; // Change background on hover
        color: black; // Change text color on hover
    }
}
.ss_add_content_subdiv1 .ss_editor{
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
}
.ss_add_content_subdiv1 .ss_preview{
    position: relative;
    height: 100%;
    width: 50%;
    padding: 10px;
    border: 1px solid grey;
    overflow: hidden;
    &::-webkit-scrollbar {
        width: 12px;
	background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: black;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar thumb on hover */
    }
}

.ss_add_content_subdiv1 .ss_row{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ss_add_content_subdiv1 .ss_container{
    position: relative;
    height: 100vh;
    width: 100%;
}
.ss_add_content_subdiv1 .ss_editor_input{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.ss_rich_text_page {
     .ss_preview {
        &::-webkit-scrollbar {
            width: 12px;
            background-color: #f2ebe6;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: black;
        }
    }

    .ss_editor_input {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}
.ql-container .ql-snow .ql-container{
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    /* height: 100%; */
    margin: 0px;
    position: relative;
}
.ql-snow .ql-editor {
    &::-webkit-scrollbar {
      width: 12px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: black;
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }
  }