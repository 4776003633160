input {
    height: 30px;
}

:placeholder-shown {
    font-size: 70%;
}

.fp_main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 30px;
}

.fp_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.fp_left_side,
.fp_right_side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fp_left_side {
    padding: 50px;
}

.fp_right_side {
    padding: 0;
}

.fp_right_image {
    height: 90vh;
    width: 100%;
    object-fit: cover;
    margin-bottom: -6px;
}

.fp_email_section {
    margin-bottom: 5%;
}

.fp_button {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.fp_button:hover {
    background-color: #797979;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.forgot_password_title {
    text-align: center;
    font-weight: bold;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.fp_email_label {
    font-weight: bold;
}

.fp_email_section input {
    background-color: #f3f4f6;
    border: none;
}

.fp_email_section input:focus {
    background-color: #f3f4f6;
    border: none;
    outline: none;
    box-shadow: none;
}

.back_to_login {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin: 10px;
    font-size: 13px;
    color: #1c1c1c;
}

.back_to_login:hover {
    color: #797979;
}

.fp_sub_title {
    text-align: center;
    font-size: 13px;
    color: gray;
}

.confirm-btn {
    display: block;
    margin: 20px auto 0;
    padding: 5px 20px 8px 20px;
    background-color: #1c1c1c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.confirm-btn:hover {
    background-color: #797979;
}

.popup_title {
    text-align: center;
}

.icon-wrapper {
    text-align: center;
    margin: 0 0 5px 0;
}

.icon-email {
    font-size: 90px;
    color: black;
}

.swal-button {
    background-color: #1c1c1c;
}

.swal-button:focus {
    box-shadow: none;
}

.swal-button:hover {
    background-color: #797979 !important;
}

/* Slider customization */
.slick-slider {
    width: 100%;
}
  
.slick-dots {
    bottom: 10px;
}
  
.slick-dots li button:before {
    font-size: 12px;
    color: #fff;
}
  
.slick-prev:before,
.slick-next:before {
    color: #fff;
    left: 50px;
}
  
.login_slider_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    font-size: 24px;
    color: white;
}
  
.slick-prev {
    left: 10px;
    z-index: 2;
    top: 53%;
}
  
.slick-next {
    right: 8px;
    z-index: 2;
    top: 53%;
}
  
.login_left_arrow,
.login_right_arrow {
    color: white;
    font-size: 23px;
}
