body,
html {
  font-family: "Lato", serif;
}

body {
  overflow-x: hidden;
}

.swal-button {
  background-color: #1c1c1c !important;
  color: white;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: #f8dcce !important;
  color: #1c1c1c;
  opacity: 0;
}

.react-chatbot-kit-chat-bot-avatar-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #f1f1f1 !important;
  color: #1c1c1c !important;
  padding: 8px 12px;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  font-size: 13px !important;
  max-width: 80%;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 305px !important;
  margin-left: -50px !important;
}

.react-chatbot-kit-user-chat-message {
  background-color: #1c1c1c !important;
  color: white !important;
  padding: 8px 12px;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 3px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  max-width: 80%;
  font-size: 13px !important;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-right: -50px !important;
}

.staff-btn {
  background-color: #376b7e;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
  opacity: 0;
  position: fixed;
}

.staff-btn:hover {
  background-color: #5ccc9d;
}

.chatbot-container {
  position: fixed;
  bottom: 40px;
  right: 35px;
  z-index: 2000;
  width: 360px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  color: white;
}

.control-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 8px 5px 8px 20px;
  border-bottom: 1px solid #d4d4d4;
}

.control-buttons strong {
  font-size: 20px;
  color: #1c1c1c;
  padding: 5px 10px 7px 10px;
}

.control-buttons strong:hover {
  color: #797979;
  background-color: #ebebeb;
  border-radius: 50%;
}

.control-button {
  background: none;
  border: none;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  color: white;
}

.control-button:hover {
  color: #d1eaff;
}

.chatbot-body {
  display: block;
  // padding: 10px 10px 50px 10.5px;
  background: white;
  color: white !important;
}

.control-open-button {
  background-color: #1c1c1c;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 40px;
  right: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 58px;
  height: 58px;
  z-index: 2000;
}

.control-open-button:hover {
  background-color: #797979;
}

.control-open-button .icon {
  color: white;
  font-size: 20px;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #1c1c1c !important;
}

.react-chatbot-kit-chat-btn-send:hover {
  background-color: #797979 !important;
}

.react-chatbot-kit-chat-input:focus {
  outline: none;
}

.react-chatbot-kit-chat-message-container {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  width: 362px !important;
  background-color: white;
  height: 460px !important;
}

.react-chatbot-kit-chat-header {
  width: 339px !important;
  background-color: white !important;
  border-bottom: 1px solid #d4d4d4;
  opacity: 0;
  position: fixed;
}

.react-chatbot-kit-chat-input-form {
  width: 111% !important;
}

.react-chatbot-kit-chat-input-container {
  width: 132% !important;
}

.react-chatbot-kit-chat-input {
  height: 42px;
}

.react-chatbot-kit-chat-bot-message-arrow,
.react-chatbot-kit-user-chat-message-arrow {
  opacity: 0;
}

.react-chatbot-kit-user-avatar-container {
  background-color: #f8dcce !important;
  opacity: 0;
}

.react-chatbot-kit-user-avatar-icon {
  fill: #1c1c1c !important;
}

.chatbot-logo {
  width: 80px;
}

.react-chatbot-kit-chat-btn-send-icon {
  width: 17px !important;
  margin: 10px 10px 10px 7px !important;
}

.react-chatbot-kit-chat-bot-message,
.react-chatbot-kit-user-chat-message {
  word-break: break-word;
  white-space: pre-wrap;
}

.react-chatbot-kit-chat-message-container {
  word-break: break-word;
}

.link {
  color: white;
  text-decoration: none;
}
