.ss_manage_content_all_container {
    display: flex;
    width: 100%;
}

.ss_manage_content_content {
    flex-grow: 1;
    padding: 15px 6% 6% 8%;
    overflow-x: hidden;
}

.ss_manage_content_sidebar {
    z-index: 2;
}

// header
.ss_manage_content_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ss_manage_content_line {
    width: 150%;
    margin-left: -15%;
    z-index: 1;
    color: gray;
}

.ss_manage_content_header .ss_manage_content_logo {
    height: 60px;
    width: 120px;
    max-width: 120px;
}

.ss_manage_content_header .ss_manage_content_search_section {
    display: flex;
    align-items: center;
}

.ss_manage_content_header .ss_manage_content_search_section input {
    background-color: #f3f4f6;
    border: none;
    width: 220px;
    height: 28px;
}

.ss_manage_content_header .ss_manage_content_search_section input:focus {
    background-color: #f3f4f6;
    border: none;
    outline: none;
    box-shadow: none;
    width: 220px;
}

.ss_manage_content_header .ss_manage_content_search_bar {
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 20px;
}

//Create blog button
.ss_manage_content_create_button_section {
    display: flex;
    justify-content: flex-start;
    padding-right: 20px;
    margin-top: 35px;
}

.ss_manage_content_create_button {
    background-color: #1c1c1c;
    color: white;
    padding: 8px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.ss_manage_content_create_button:hover {
    background-color: #797979;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

//Content body
.ss_manage_content_content_list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 15px;
}

.ss_manage_content_content_card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: calc(33.333% - 20px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
}

.ss_manage_content_content_card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.ss_manage_content_content_card:hover {
    transform: translateY(-5px);
}

.ss_manage_content_content_info {
    padding: 15px;
}

.ss_manage_content_creator {
    font-size: 12px;
    color: #888;
    margin-bottom: 5px;
}

.ss_manage_content_content_title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ss_manage_content_content_tags {
    margin-bottom: 10px;
}

.ss_manage_content_content_tags span {
    background-color: #eef;
    font-size: 13px;
    color: #06c;
    padding: 2px 5px 5px 5px;
    border-radius: 3px;
    margin-right: 5px;
}

.ss_manage_content_content_date {
    font-size: 12px;
    color: #888;
}

.ss_manage_content_content_actions {
    position: absolute;
    bottom: 20px;
    right: 15px;
    display: flex;
    gap: 20px;
}

.ss_manage_content_content_actions .fa-pen {
    font-size: 18px;
    cursor: pointer;
    color: #edd723;
    transition: color 0.3s ease;
}

.ss_manage_content_content_actions .fa-trash {
    font-size: 18px;
    cursor: pointer;
    color: #e03c3e;
    transition: color 0.3s ease;
}

.ss_manage_content_content_actions i:hover {
    color: #0092f7;
}

@media (max-width: 650px) {
    .ss_manage_content_content_list {
        flex-direction: column;
    }
    .ss_manage_content_content_card {
        width: 100%;
    }
}