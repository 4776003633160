// Banner
.news_banner_main_wrapper {
    position: relative;
    opacity: 0;
    transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out; 
    margin-top: -20px;
}

.news_banner_main_wrapper.visible {
    opacity: 1; 
    transform: translateY(20px);
}

.news_banner_image img {
    width: 100%;
    height: 430px;
    margin-bottom: 40px;
}
// End banner 


.filters_and_products {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
}

.filters_products {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: -20px;

    .filter_group {
        margin: 0 20px;
        width: 120px;

        label {
            margin-bottom: 5px;
        }

        .MuiFormControl-root {
            width: 90%;
        }
    }

    .filter_group_sort {
        margin: 0 20px;
        width: 200px;
    }

    .filter_group_remove {
        margin: 0 20px;
        color: #1c1c1c;
        border: 1px solid #1c1c1c;
    }
}

.product_list_note_wrapper{
    text-align: center;
    font-style: italic;
}