// Contact
.contact_container {
    margin: 50px 5% 100px 5%;
    text-align: center;
}

.contact_title {
    font-size: 24px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 27px;
}

.contact_card {
    // width: 23%;
    margin: 5px 5px;
    height: 200px;
    padding: 25px 18px 20px 18px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.contact_icon i {
    font-size: 40px;
    color: #1c1c1c;
    margin-bottom: 10px;
}

.contact_card_title {
    font-size: 18px;
    font-weight: bold;
    color: #111;
    margin-bottom: 10px;
}

.contact_card_description {
    font-size: 14px;
    color: #565e6c;
}

.contact_card a{
    text-decoration: none;
    color: #1c1c1c !important;
}

.contact_card a:hover{
    color: #797979 !important;
}

.contact_card_title p{
    color: #1c1c1c !important;
}

.contact_card_title:hover p{
    color: #797979 !important;
}

// End contact 