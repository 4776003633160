.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 200px 0 30px 0;
}

.loading_content{
    text-align: center;
    margin-bottom: 200px;
    font-size: 18px;
}