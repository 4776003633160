// *{
//     font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// }
.ss_add_content_all_container {
    display: flex;
    width: 100%;
}

.ss_add_content_content {
    flex-grow: 1;
    padding: 15px 6% 6% 8%;
    overflow-x: hidden;
}

.ss_add_content_sidebar {
    z-index: 2;
}

// header
.ss_add_content_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ss_add_content_line {
    width: 150%;
    margin-left: -15%;
    z-index: 1;
    color: gray;
}

.ss_add_content_header .ss_add_content_logo {
    height: 60px;
    width: 120px;
    max-width: 120px;
}

.ss_add_content_content_form{
    width: 95%;
}
.ss_add_content_title{
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 24px;
    font-weight: 500;
    margin-top: 2%;
    margin-bottom: 10px;
    text-align: center;

}
.ss_add_content_subdiv1{
    display: flex;
    flex-direction: column;
    //width: 50%;
}
.ss_add_displayed_image_div2{
    margin-top: 3%;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #d1d1d1;
    align-items: flex-start;
    padding: 3%;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

}
.ss_enter_image{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-top: 1%;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}
.ss_add_more_infor{
    display: flex;
    flex-direction: row;
    width: 100%;

}
.ss_add_creator_date{
    margin-left: 19%;
}
.ss_add_subdiv3{
    display: flex;
    flex-direction: column;
}
.ss_add_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.ss_add_information_div1{
    position: relative;
    border-radius: 7px;
    border: 1px solid #d1d1d1;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 10px;  
    height: 100%; 
    width: 100%;
}
.ss_add_title_input{
    width: 100%;
    height: 30px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 4px 10px;
    margin-left: 10px;    
    margin-bottom: 5px;
}
  .ss_add_content_subdiv1:hover .ss_add_title_input {
    border: 1px solid black;
  }
  .ss_add_subdiv3:hover .ss_add_title_input{
    border: 1px solid black;
  
  }
  .ss_enter_image:hover{
    border: 1px solid black;
  
  }
.ss_add_content_label{
    font-size: 16px;
    padding: 5px 10px;
    width: 50%;
}
.ss_add_content_richtext{
    position: relative;
   width: fit-contents;
    height: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    padding: 5 10px;
    margin-left: 10px;    
    margin-bottom: 10%;
    position: relative;
    overflow-x: hidden;
}
#imagePreview {
    border-radius: 5px;
    margin-top: 10px;
    width: fit-content;
  }
.ss_add_all_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.ss_add_content_back_button{
    background-color: white;
    border: none;
    margin-right: 5%;
    padding: 10px 25px;
    cursor: pointer; // Change cursor to pointer on hover
    font-size: 16px; // Adjust font size as needed
    text-align: center;
    transition: background 0.3s ease, color 0.3s ease; // Add transition for hover effects

    &:hover {
        color: black; // Change text color on hover
        text-decoration: underline;
    }
}
.ss_new_window_button:hover {
    animation: jump-div 0.5s ease;
  }
  
  @keyframes jump-div {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
  }
  
  .ss_new_window_button:hover .ss-navigate-button {
    text-decoration: underline;
  }
.ss_back_container{
    text-align: right;
}
.ss_add_rich_text{
    position: relative;
    width: 90%;
    height: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    padding: 5 10px;
    margin-left: 10px;    
    margin-bottom: 10%;
    position: relative;
    overflow-x: hidden;
}
.ss-navigate-button{
    width: 100%;
    background-color: white;
    border: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.ss_text_editor_nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/* Style the scrollbar track */
.ss_add_rich_text::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background color */
  }
  
  @media (max-width: 600px) {
    .ss_add_more_infor {
        flex-direction: column;
        align-items: start;
    }
    .ss_add_creator_date{
        margin-left: 0px;
    }
}
