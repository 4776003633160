.cart_empty_message {
   text-align: center;
   margin-left: auto;
   margin-right: auto;
}

.text-diamond-data{
    color: #797979;
    font-size: 15px;
    margin-bottom: 5px;
}

.cart_header {
    display: flex;
    justify-content: space-between;
    padding: 20px 21% 20px 270px;
    margin: 40px 205px -25px -177px;
}

.cart_header i {
    margin-right: 5px;
    color: black;
}

.cart_header .cart_title {
    font-weight: bold;
    font-size: 17px;
}

.cart_header .continue_shopping {
    font-size: 15px;
    padding-top: 2px;
    cursor: pointer;
}

.cart_header .continue_shopping:hover {
    color: #797979;
}

.cart_item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart_container {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px 90px 40px;
}

.cart_items {
    flex: 3;
    height: 100%;
    margin-bottom: 100px;
}

.cart_item {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px 20px 15px 25px;
    margin: 0px 40px 20px 50px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    max-width: 87%;
}

.cart_item_image {
    width: 190px !important;
    height: 170px !important; 
    border-radius: 8px !important;
    margin-right: 25px !important;
    margin-left: -10px !important;
}

.cart :where(.css-dev-only-do-not-override-1pg9a38).ant-image .ant-image-mask {
    width: 190px !important;
    height: 170px !important;
    border-radius: 8px !important;
    margin-right: 25px !important;
    margin-left: -10px !important;
}

.cart .ant-image-mask {
    width: 190px !important;
    height: 170px !important;
    border-radius: 8px !important;
    margin-right: 25px !important;
    margin-left: -10px !important;
}

.cart_item_details {
    flex: 1;
}

.cart_item_name {
    font-size: 20px !important;
    font-weight: bold;
}

.cart_item_description {
    font-size: 15px;
    color: #555;
    margin: 0 112px 0 0;
}

.cart_item_actions {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.cart_item_size {
    padding: 5px;
    margin-right: 10px;
}

.cart_item_size_guide {
    color: #1c1c1c;
    text-decoration: underline;
    cursor: pointer;
}

.cart_item_size_guide:hover {
    color: #797979;
}

.cart_size_guide_container {
    margin-top: 10px;
}

.cart_ring_size_detail {
    border-radius: 0;
    padding: 1px 0 2px 10px;
    font-size: 15px;
    outline: none;
}

.cart_item_price {
    font-size: 20px;
    font-weight: bold;
    margin: 48px 0 0 0;
    text-align: end;
}

.cart_item_links {
    display: flex;
    gap: 10px;
    font-size: 14px;
    margin-top: -5px;
}

.cart_item_view,
.cart_item_remove {
    color: #1c1c1c;
    cursor: pointer;
    text-decoration: none;
}

.cart_item_view:hover,
.cart_item_remove:hover {
    color: #797979;
}

.cart_summary {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: -2px 47px 0 17px;
    background-color: #f8f9fa;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 310px;
    max-height: 310px;
}

.cart_summary_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.cart_summary_details {
    margin-bottom: 20px;
}

.cart_summary_subtotal,
.cart_summary_total {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
}

.cart_summary_checkout {
    background-color: #1c1c1c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.cart_summary_checkout:hover {
    background-color: #797979;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cart_summary_service {
    font-size: 14px;
    text-align: center;
}

.cart_summary_service i {
    margin-right: 5px;
}

.cart_summary_service a {
    text-decoration: none;
    color: #1c1c1c !important;
}

.cart_summary_service a:hover {
    color: #797979 !important;
}

.cart_summary .cart_summary_title i {
    margin-right: 12px;
    font-size: 20px;
    color: #e05858;
}

.cart_summary_service .phone_service {
    margin-top: -5px;
    font-size: 13px;
    margin-bottom: 0;
}

.cart_summary_service .phone_service i {
    margin-top: -5px;
    font-size: 13px;
}

//Size guide
.no-scroll {
    overflow: hidden;
}

.cart_size_guide_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cart_size_guide_content {
    position: relative;
    padding: 10px;
    background: white;
    border-radius: 10px;
}

.cart_size_guide_content i {
    font-size: 20px;
    margin-right: 10px;
}

.cart_size_guide_content i:hover {
    color: #0f2770;
}

.cart_size_guide_content img {
    width: 520px;
    height: 520px;
    text-align: center;
}

.cart_close_size_guide {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
}

.cart_size_guide_detail {
    border: none;
    background-color: transparent;
    margin-left: 10px;
    font-size: 14px;
    color: #1c1c1c;
    text-decoration: underline;
}

.cart_size_guide_detail:hover {
    text-decoration: underline;
    color: #797979;
}

//End size guide

.disabled {
    pointer-events: none;
}

.unclickable {
    pointer-events: none;
    cursor: default;
}

.text-grey {
    color: grey;
    margin-bottom: 5px !important;
    font-size: 15px;
}

.text-grey-price{
    color: grey;
    margin-bottom: 5px !important;
    font-size: 20px !important;
}

.out-of-stock-text {
    color: #e05858;
}

.cart_item_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .cart_item_quantity {
    font-size: 20px;
    font-weight: bold;
    margin-top: 46px;
  }
  