.product_detail_container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.product_images_detail {
    flex: 1;
    display: flex;
    margin-left: 6%;

    .thumbnails {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .thumbnail {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border: 1px solid transparent;
            cursor: pointer;
            transition: border-color 0.3s;

            &.selected {
                border-color: #1c1c1c;
            }
        }
    }

    .main_image {
        width: 415px;
        height: 400px;
        border: 1px solid #d7d7d7;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
        margin: 0 0 0 15px;
        padding: 0;
    }

    .product_contact_detail {
        display: flex;
        justify-content: space-between;
        background-color: #f2f2f2;
        padding: 30px 50px;
        margin-top: 20px;
        width: 415px;

        p {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 0;

            i {
                margin-right: 10px;
                font-size: 16px;
            }
        }
    }
}

.product_info_detail {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 6%;

    .product_name_detail {
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .product_description_detail {
        font-size: 16px;
        color: #797979;
        margin-bottom: 20px;
    }

    .product_code_detail,
    .product_weight_detail,
    .product_shell_detail,
    .product_diamond_detail {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .price_size_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 18px 0 25px 0;

        .product_price_detail {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 0;
        }

        .size_guide_container {
            display: flex;
            align-items: center;
            gap: 10px;
            outline: none;

            .size_guide_detail {
                font-size: 14px;
                color: #1c1c1c;
                text-decoration: underline;
                cursor: pointer;
                background-color: transparent;
                border: none;
                outline: none;
            }

            .size_guide_detail:hover {
                color: #797979;
                text-decoration: underline;
            }

            .ring_size_detail {
                padding: 5px 20px 7px 15px;
                font-size: 16px;
                border-radius: 0;
                outline: none;
            }
        }
    }

    .product_actions_detail {
        margin-bottom: 20px;

        .add_to_cart_btn {
            background-color: #1c1c1c;
            color: white;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            border-radius: 30px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            width: 100%;

            &:hover {
                background-color: #797979;
                color: white;
                transform: translateY(-5px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

                i {
                    color: white;
                }
            }

            i {
                font-size: 16px;
                color: white;
            }
        }
    }


    // .checkout_summary_checkout {
    //     background-color: #1c1c1c;
    //     color: white;
    //     padding: 10px 20px;
    //     border: none;
    //     border-radius: 8px;
    //     cursor: pointer;
    //     width: 100%;
    //     margin-top: 10px;
    //     margin-bottom: 20px;
    //     transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    // }

    // .checkout_summary_checkout:hover {
    //     background-color: #797979;
    //     color: white;
    //     transform: translateY(-5px);
    //     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    // }

    .product_delivery_detail {
        a {
            text-decoration: none;
            color: #1c1c1c !important;
        }

        a:hover {
            color: #797979 !important;
        }

        p {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-bottom: 10px;

            .fa-shipping-fast {
                margin-right: 10px;
                font-size: 20px;
            }

            .fa-calendar-alt {
                margin-right: 11px;
                font-size: 22px;
                margin-left: 2px;
            }

            .fa-phone {
                margin-right: 11px;
                font-size: 20px;
                margin-left: 2px;
            }
        }
    }

    .product_detail_line {
        color: gray;
        margin: 20px 0;
    }
}

.shell_checkbox {
    margin: 0 10px;
    height: 13px;
}

// End product detail 

//Size guide
.size_guide_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.size_guide_content {
    position: relative;
    padding: 10px;
    background: white;
    border-radius: 10px;
}

.size_guide_content i {
    font-size: 20px;
    margin-right: 10px;
}

.size_guide_content i:hover {
    color: #0f2770;
}

.size_guide_content img {
    width: 520px;
    height: 520px;
    text-align: center;
}

.close_size_guide {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
}

//End size guide

//Specification
.product_specification_container {
    margin-top: 30px;
    padding: 30px;
    background-color: white;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 50px 6% 60px 6%;
    border: 1px solid #d7d7d7;
}

.product_specification_title {
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.specification_toggle_icon {
    margin-left: 10px;
}

.product_specification_carat,
.product_specification_color,
.product_specification_clarity,
.product_specification_cut,
.product_specification_sub_diamond_amount,
.product_specification_diamond_amount,
.product_specification_diamond_size,
.product_specification_trademark,
.product_specification_collection,
.product_specification_main_diamond_amount {
    font-size: 16px;
    padding: 7px 40px;
    margin-bottom: 0;
}

.product_specification_diamond_amount,
.product_specification_cut,
.product_specification_clarity,
.product_specification_main_diamond_amount {
    background-color: #f2f2f2;
}

.product_specification_line {
    color: black;
}


// Just for you
.just_for_you_container {
    display: flex;
    background-color: #f8dcce;
    color: #1c1c1c;
    padding: 20px 70px 5px 70px;
    margin-top: 80px;
    margin-bottom: 60px;

    .just_for_you_text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 60px;

        h3 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        p {
            font-size: 15px;
            line-height: 1.5;
        }
    }

    .just_for_you_features {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .feature {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            i {
                font-size: 30px;
                margin-right: 20px;
                color: #1c1c1c;
            }

            p {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
    }

    .jfy_line1 {
        border-top: 1px solid #1c1c1c;
        margin: 5px 0 15px 0;
        width: 100%;
        opacity: 1;
    }

    .jfy_line2 {
        border-top: 1px solid #1c1c1c;
        margin: 5px 0 15px 0;
        width: 100%;
        opacity: 1;
    }
}

// Also like
.also_like_container {
    margin-top: 85px;
    padding: 20px 0 20px 2%;
    text-align: center;
    position: relative;
    margin-bottom: 80px;

    .also_like_title {
        font-size: 26px;
        color: #1c1c1c;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .also_like_wrapper {
        display: flex;
        overflow: hidden;
        padding: 20px 0;
        justify-content: center;
        position: relative;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }

    .also_like_card {
        position: relative;
        flex: 0 0 260px;
        height: 360px;
        margin-right: 25px;
        text-align: center;
        border: 1px solid #e0e0e0;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        scroll-snap-align: center;
        cursor: pointer;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding: 0 0 10px 0;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            background-color: #1c1c1c;
            transition: height 0.3s ease-in-out, transform 0.3s ease-in-out;
            z-index: 1;
        }

        &:hover::after {
            height: 35px;
            transform: translateY(-15px);
        }

        .also_like_image {
            width: 100%;
            height: 240px;
            margin-bottom: 25px;
            transition: transform 0.3s ease-in-out;
            transform-origin: bottom;
        }

        &:hover .also_like_image {
            transform: scale(1.1);
        }

        .also_like_name {
            font-size: 15px;
            margin-bottom: 10px;
            transition: transform 0.3s ease-in-out;
            margin-top: 20px;
        }

        &:hover .also_like_name {
            transform: translateY(-5px);
        }

        .also_like_price {
            font-size: 16px;
            transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
            position: relative;
            z-index: 2;
            font-weight: bold;
            margin-top: 17px;
        }

        &:hover .also_like_price {
            color: white;
            transform: translateY(-10px);
        }

        .also_product_view_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -200px;
            left: 190px;
            font-size: 17px;
            color: #1c1c1c;
            background-color: white;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            padding: 1px 0.5px 0 0;
            transition: opacity 0.3s ease-in-out, transform 0.4s ease-in-out;
            opacity: 0;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
            z-index: 5;
            cursor: pointer;
        }

        &:hover .also_product_view_icon {
            opacity: 1;
            transform: translateY(-45px);
        }

        .also_product_view_icon_wrapper {
            position: relative;
        }

        .also_product_view_icon_wrapper[data-tooltip]::after {
            content: attr(data-tooltip);
            position: absolute;
            left: calc(100% + -150px);
            top: -235px;
            background-color: #1c1c1c;
            color: #fff;
            padding: 5px 10px;
            border-radius: 3px;
            white-space: nowrap;
            opacity: 0;
            transition: opacity 0.3s;
            z-index: 1;
            font-size: 12px;
        }

        .also_product_view_icon_wrapper:hover::after {
            opacity: 1;
        }

        .also_like_detail {
            font-size: 13px;
            color: #797979;
            margin: -5px 0 -15px 0;
            transition: transform 0.3s ease-in-out;
        }

        &:hover .also_like_detail {
            transform: translateY(-5px);
        }

    }

    .nav_arrow {
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        font-size: 20px;
        z-index: 1;
        user-select: none;
        padding: 6px;
    }

    .left_arrow {
        left: 3%;
    }

    .right_arrow {
        right: 3%;
    }

    .nav_arrow:hover {
        background-color: #e0e0e0;
    }
}


.no-scroll {
    overflow: hidden;
}

.GIA_image_wrapper,
.GIA_content {
    text-align: center;
}

.GIA_image_wrapper img {
    width: 900px;
    height: 1100px;
    border: 1px solid #cac9cd;
    border-radius: 5px;
}

.GIA_content {
    margin: 20px 0 140px 0;
    font-style: italic;
}

.product_diamond_description {
    font-size: 17px;
    color: #797979;
}

.product_detail :where(.css-dev-only-do-not-override-1pg9a38).ant-image .ant-image-mask {
    width: 415px;
    height: 400px;
    border: 1px solid #d7d7d7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 0 0 0 15px;
    padding: 0;
}

.product_detail .ant-image-mask {
    width: 415px !important;
    height: 400px !important;
    border: 1px solid #d7d7d7 !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;
    margin: 0 0 0 15px !important;
    padding: 0 !important;
}