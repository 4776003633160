.manager_add_diamond_all_container {
    display: flex;
    width: 100%;
}
.manager_product_detail_subcontent {
    overflow-y: auto; 
    scrollbar-width: none; 
  }
  
  .manager_product_detail_subcontent::-webkit-scrollbar {
    display: none;
  }
.manager_add_diamond_content {
    flex-grow: 1;
    padding: 10px 8% 0 8%;
    overflow-x: hidden;
}

.manager_add_diamond_sidebar {
    z-index: 2;
}

//Header
.manager_add_diamond_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manager_add_diamond_header_line {
    width: 150%;
    margin-left: -15%;
    z-index: 1;
    color: gray;
}

.manager_add_diamond_header .manager_add_diamond_logo {
    height: 60px;
    width: 120px;
    max-width: 120px;
    margin-bottom: 5px;
}

//Add diamond button
.manager_add_diamond_submit_button {
    display: flex;
    justify-content: center;
    padding-right: 20px;
    margin-top: 20px;
}


.manager_add_diamond_submit_button {
    background-color: #1c1c1c;
    color: white;
    padding: 8px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.manager_add_diamond_submit_button:hover {
    background-color: #797979;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

//Add diamond form
.manager_add_diamond_title_back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.manager_add_diamond_title {
    font-weight: bold;
    color: #1c1c1c;
}

.manager_add_diamond_back_button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #1c1c1c;
}

.manager_add_diamond_back_button:hover{
    color: #797979;
}

.manager_add_diamond_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e0e0e0;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}



.manager_add_diamond_form_row {
    display: flex;
    gap: 20px;
}
@media screen and (max-width: 500px) {
    .manager_add_diamond_form_row {
       flex-direction: column;
    }
}
.manager_add_diamond_form_group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.manager_add_diamond_form_group label {
    font-weight: bold;
}

.manager_add_diamond_form_group input {
    background-color: #f3f4f6;
    border: none;
    height: 28px;
    padding: 0 10px;
}

.manager_add_diamond_form_group input:focus {
    background-color: #f3f4f6;
    border: none;
    outline: none;
    box-shadow: none;
}

.manager_add_diamond_form_group select{
    outline: none;
    height: 28px;
}
.manager_product_detail_box{
    width: 100%;
    max-width: 1300px;
    margin: 3% auto;
    border: 0.5px solid #cccccc;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 0.1), 0 2px 2px -2px rgb(0 0 0 / 0.1);
}
.manager_product_detail_subcontent{
    padding: 5px 20px;
}
.manager_back_button{
    background-color: white;
    color: black;
    padding: 8px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.manager_back_button:hover{
    text-decoration: underline;
}
@media screen and (max-width: 650px) {
    .manager_add_diamond_form_row {
       flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .responsive-select {
        width: 100%;
    }
}  
@media screen and (max-width: 450px) {
    .collectionId {
        width: 100%;
    }
}

.manager_add_diamond_form_group .collectionId{
    width: 100%;
    flex-direction: column;
}
.file-upload-container {
    border: 2px dashed #ccc;
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.file-upload-container .file-dropzone {
    position: relative;
}

.file-upload-container img {
    width: 50px;
    margin-bottom: 10px;
}

.file-upload-container input[type="file"] {
    display: none;
}

.file-upload-container label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.file-details {
    margin-top: 20px;
    text-align: left;
}

.file-details .file-info {
    margin-bottom: 10px;
}

.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 6px;
}